
import Vue from "vue";
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);

export default Vue.extend({
  name: "Navigation",
  props: {
    Vue,
  },
});
