<template>
  <div id="app" class="container-fluid p-0">
    <Navigation></Navigation>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Chart from "@/components/Chart.vue";
/* import CookieBanner from '@/components/CookieBanner.vue' */
import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
/* import VueGtag from 'vue-gtag' */

import VueCookies from "vue-cookies-ts";

/*
Vue.use(VueGtag, {
  config: { id: (process.env.NODE_ENV === 'production') ? 'G-CCY4ZSQG0K' : 'G-N7L1Z9PE99' },
  bootstrap: false
})
*/

export default {
  name: "App",
  components: {
    Navigation,
    // CookieBanner
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-vue/src/index.scss";
</style>
