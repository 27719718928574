<template>
  <div
    class="home container pt-5 px-5 d-flex align-items-center justify-content-center adjustHeight"
    style=""
  >
    <section
      class="mt-5 px-1 px-sm-0 mt-sm-0"
      id="dsd"
      style="text-align: center"
    >
      <h2 class="">Johannes Laurenz Knecht</h2>
      <div class="subheading mb-5">
        Kittlerstraße 37 · 64289 Darmstadt · 017679975344 ·
        <a href="mailto:Kontakt@JLKMedia.de">Kontakt@JLKMedia.de</a>
      </div>
      <p class="mb-5">Web & Softwareentwicklung sowie IT-Beratung.</p>
      <!--<ul class="list-inline list-social-icons mb-0">
          <li class="list-inline-item">
            &lt;!&ndash;<a href="https://www.linkedin.com/in/johannes-laurenz-knecht-b21280145/">
                  <span class="">
                    Linkedin
                  </span>
            </a>&ndash;&gt;
          </li>
          <li class="list-inline-item">
            <a href="https://github.com/JohannesKnecht">
                  <span class="fa-stack fa-lg">
                    GitHub
                  </span>
            </a>
          </li>
        </ul>-->
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
<style scoped lang="scss">
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-vue/src/index.scss";
.adjustHeight {
  @include media-breakpoint-only(xl) {
    min-height: 680px;
  }
  @include media-breakpoint-between(xs, lg) {
    min-height: 280px;
  }
}
</style>
