import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Impressum.vue"),
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Datenschutz.vue"),
  },
  {
    path: "/dienstleistungen",
    name: "Dienstleistungen",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dienstleistungen.vue"),
  },
  {
    path: "/machinelearning",
    name: "Machinelearning",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Machinelearning.vue"),
  },
  {
    path: "/machinelearning/XOR",
    name: "XOR",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/machinelearning/XOR.vue"
      ),
  },
  {
    path: "/machinelearning/covid19",
    name: "Covid19",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/machinelearning/Covid-19.vue"
      ),
  },
  {
    path: "/cv",
    name: "CV",
    component: () => import(/* webpackChunkName: "about" */ "../views/cv.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  base: "/",
  routes,
});

export default router;
