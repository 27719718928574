
import * as d3 from "d3";
import Vue from "vue";
import { PropType } from "vue";
import { Point2D } from "@/ts/ml/covid19/PlotWrapper";
import { ValueFn } from "d3-selection";

export interface Dataset {
  data: Point2D[];
  color: string;
}

/* eslint-disable */
export default Vue.extend({
  name: "Chart",
  data: function () {
    return {
      wrapper: d3
        .select("#wrapper")
        .append("svg")
      }
  },
  props: {
    name: String,
    plotData: {
      type: Array as PropType<Dataset[]>, // use PropType
      default: () => [] // use a factory function
    },
    strokeColor: String,
    vw: Number,
    vh: Number,
  },
  mounted(): void {
    this.setWrapper();
    this.plotData.map((val: any) => this.triggerPlotDraw(val.data, val.color));
    this.setHeader();
  },
  computed: {
    dimensions: function (): {
      width: number,
      height: number,
      margin: {
        top: number,
        right: number,
        bottom: number,
        left: number,
      },
      boundedWidth: number,
      boundedHeight: number,
    } {
      let dimensions = {
        width: this.vw > 1920 ? 1920 : this.vw,
        height: this.vh > 1080 ? 1080: this.vh, // * 0.8,
        margin: {
          top: 50,
          right: 55,
          bottom: 50,
          left: 55,
        },
        boundedWidth: 0,
        boundedHeight: 0,
      };
      dimensions.boundedWidth =
        dimensions.width - dimensions.margin.left - dimensions.margin.right;
      dimensions.boundedHeight =
        dimensions.height - dimensions.margin.top - dimensions.margin.bottom;
      return dimensions;
    },
    totalDataPoint2D: function (): Point2D[] {
      /*console.log(
        this.plotData
          .map((val: any) => val.data)
          .reduce((a, b) => a.concat(b))
          .map((val: Point2D) => [new Date(val.x*86400000 + 1582511040000).toDateString(), val.y])
      )*/
      return this.plotData
        .map((val: any) => val.data)
        .reduce((a, b) => a.concat(b));
    },
    totalData: function (): [] {
      return this.plotData
        .map((val: any) => val.data)
        .reduce((a, b) => a.concat(b))
        .map((val: Point2D) => [val.x, val.y]);
    },

    yScale: function (): any {
      // eslint-disable-next-line
      return d3
        .scaleLinear()
        .domain([0, Math.max( ...this.totalDataPoint2D.map((p: Point2D) => p.y))]) // d3.extent(this.totalData, this.yAccessor)
        .range([this.dimensions.boundedHeight, 0]);
    },
    referenceBandPlacement: function (): number {
      return this.yScale(100);
    },
    xScale: function (): any{
      // .scaleTime()
      return d3
        .scaleLinear([0, this.dimensions.boundedWidth])
        .domain(
          [
            1582511040000,
            Math.max( ...this.totalDataPoint2D.map(
              (p: Point2D) => this.xAccessorPoint2D(p)
              )
            )
          ]
        )
      // d3.extent(this.totalData, this.xAccessor)
    },
  },

  methods: {
    setWrapper: function(): any {
      this.wrapper = d3
        .select("#wrapper")
        .append("svg")
        .attr("width", this.dimensions.width)
        .attr("height", this.dimensions.height);
    },
    yAccessorPoint2D: function (d: Point2D ): number {
      return d.y;
    },
    xAccessorPoint2D: function (d: Point2D ): number {
      return d.x*86400000 + 1582511040000;
    },
    yAccessor: function (d: [number, number]): number {
      return d[1];
    },
    xAccessor: function (d:  [number, number]): number {
      return d[0]*86400000 + 1582511040000;
    },

    getBounds: function () {
      return this.wrapper
        .append("g")
        .style(
          "transform",
          `translate(${this.dimensions.margin.left}px,${this.dimensions.margin.top}px)`
        );
    },
    getReferenceBand: function (): any {
      return this.getBounds()
        .append("rect")
        .attr("x", 0)
        .attr("width", this.dimensions.boundedWidth)
        .attr("y", this.referenceBandPlacement)
        .attr(
          "height",
          this.dimensions.boundedHeight - this.referenceBandPlacement
        )
        .attr("fill", "#ffece6");
    },
    setHeader: function (): void {
      this.wrapper
        .append("g")
        .style("transform", `translate(${50}px,${15}px)`)
        .append("text")
        .attr("class", "title")
        .attr("x", this.dimensions.width / 2)
        .attr("y", this.dimensions.margin.top / 2)
        .attr("text-anchor", "middle")
        .text(this.name)
        .style("font-size", "9px"); //.style("text-decoration", "underline")
    },

    triggerPlotDraw: function (plotDataset: Point2D[], color: string): void {
      this.getBounds()
        .append("path")
        .attr(
          "d",
          d3.line(
            (d) => this.xScale(this.xAccessor(d)),
            (d) => this.yScale(this.yAccessor(d))
          ).curve(d3.curveBasis)(
            plotDataset.map((val: Point2D) => [val.x, val.y])
          ) as any
        )
        .attr("fill", "none")
        .attr("stroke", color)
        .attr("stroke-width", 2);


      const yAxisGenerator = d3.axisLeft(this.yScale);
      const yAxis = this.getBounds().append("g").call(yAxisGenerator);

      // Generate X Axis
      const xAxisGenerator = d3.axisBottom(this.xScale);
      const xAxis = this.getBounds()
        .append("g")
        .call(xAxisGenerator.tickFormat(d3.timeFormat(this.vw>800?"%x":"%b") as any)) //%b, %y // .call(xAxisGenerator) //
        .style("transform", `translateY(${this.dimensions.boundedHeight}px)`);
    },
  },
  watch: {
    plotData(): void {
      d3.select("#wrapper").selectAll("*").remove();
      this.setWrapper();
      this.plotData.map(
        (val: { data: Point2D[]; color: string }): void =>
          this.triggerPlotDraw(val.data, val.color)
      );
      // this.setHeader();
    },
    vw(): void {
      d3.select("#wrapper").selectAll("*").remove();
      this.setWrapper();
      this.plotData.map(
        (val: { data: Point2D[]; color: string }): void =>
          this.triggerPlotDraw(val.data, val.color)
      );
    },
    vh(): void {
      d3.select("#wrapper").selectAll("*").remove();
      this.setWrapper();
      this.plotData.map(
        (val: { data: Point2D[]; color: string }): void =>
          this.triggerPlotDraw(val.data, val.color)
      );
    },
  }
});
