import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueCookies, { Cookies } from "vue-cookies-ts";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueCookies);

declare module "vue/types/vue" {
  interface Vue {
    $cookies: Cookies;
  }
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// const app = createApp(Root)
